<template>
  <div class="coupon-content-setting relative">
    <PageTitle :title="pageTitle" hideBtn />
    <section>
      <!-- <p class="view-title">{{ pageTitle }}</p> -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/coupon-setting' }">
          使用券設定
        </el-breadcrumb-item>
        <el-breadcrumb-item>使用券編輯設定</el-breadcrumb-item>
      </el-breadcrumb>
    </section>
    <section class="card-container">
      <div class="card-content">
        <p class="title">基本設定</p>
        <AiHintBlock
          v-if="mode === 'create'"
          :hintLoading="hintLoading"
          showKeepOriginalContent
          @confirm="handleConfirm"
        />
        <BaseElForm
          ref="form"
          v-loading="hintLoading"
          :model="formData"
          :rules="formRules"
          label-position="top"
        >
          <BaseElFormItem label="圖片（最大寬度 2000 px）" prop="img">
            <UploadButton
              :img="formData.img"
              :isAvatar="true"
              @change="loadImg"
            />
          </BaseElFormItem>

          <div class="content-col">
            <BaseElFormItem label="使用券名稱" prop="name">
              <BaseElInput
                v-model="formData.name"
                placeholder="請輸入"
                maxlength="50"
              />
            </BaseElFormItem>

            <BaseElFormItem v-if="mode === 'create'" label="到期方式" prop="expType">
              <BaseElSelect
                v-model="formData.expType"
                placeholder="請選擇到期方式"
                @change="onExpTypeChange"
              >
                <BaseElSelectOption
                  v-for="data in expTypeOption"
                  :key="data.value"
                  value-key="key"
                  :label="data.label"
                  :value="data.value"
                />
              </BaseElSelect>
            </BaseElFormItem>

            <BaseElFormItem v-if="mode !== 'create'" label="到期方式" prop="expType">
              {{ formatExpPreviewText({
                expType: formData.expType,
                expDay: formData.expDay,
                endOfPeriodUnit: formData.endOfPeriodUnit,
                expAt: formData.expAt,
              }) }}
            </BaseElFormItem>
          </div>
          <div class="content-col">
            <BaseElFormItem
              v-if="formData.expType === 'expDay'"
              label="領取後可使用天數"
              prop="expDay"
            >
              <BaseElInput v-model="formData.expDay" placeholder="請輸入" />
            </BaseElFormItem>
            <BaseElFormItem
              v-if="formData.expType === 'expAt'"
              label="特定截止日期"
              prop="expAt"
            >
              <p>
                (若用於抽獎獎項，截止日期不可早於活動結束日，避免會員抽到過期使用券)
              </p>
              <el-date-picker
                v-model="formData.expAt"
                editable
                :disabled="mode !== 'create'"
                :picker-options="datePickerOptions"
                type="datetime"
                placeholder="選擇日期時間"
                format="yyyy-MM-dd HH:mm:ss"
              />
            </BaseElFormItem>
          </div>
        </BaseElForm>
      </div>
    </section>
    <section class="card-container">
      <div class="card-content">
        <p class="title">注意事項</p>
        <quillEditor
          v-model="formData.notice"
          v-loading="hintLoading"
          :options="editorOption"
        />
      </div>
    </section>

    <PageFixedFooter
      :confirmBtn="btnTitle"
      @confirm="confirm"
      @cancel="$router.go(-1)"
    />

    <!-- <section class="page-footer">
      <BaseElButton plain @click="$router.go(-1)">取消</BaseElButton>
      <BaseElButton type="primary" @click="confirm">{{ btnTitle }}</BaseElButton>
    </section> -->

    <ImageCropper
      v-if="uploadDialog"
      :image="uploadImg"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
  </div>
</template>

<script>
import { noEmptyRules, isDigitRules, maxNumberRules } from '@/validation/index'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ImageCropper from '@/components/ImageCropper.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import formUtils from '@/utils/form'
import dayjs from '@/lib/dayjs'
import AiHintBlock from '@/components/AiHintBlock.vue'
import { useAi } from '@/use/useAi'
import { set, ref } from 'vue'
import { keys, has, get } from 'lodash'
import { hint } from '@/config/hint'
// APIs
import { CreateCoupon, FindCoupon, UpdateCoupon } from '@/api/lottery/coupon'

// Mixin
import imageMixin from '@/mixin/image'
import { mapGetters } from 'vuex'
import { useCoupon } from '@/use/useCoupon'
export default {
  name: 'CouponCreate',
  components: { quillEditor, ImageCropper, UploadButton, AiHintBlock },
  mixins: [imageMixin],
  setup () {
    const formData = ref({
      expAt: '',
      expDay: '',
      expType: 'expDay',
      img: null,
      name: '',
      exp: '',
      notice: '',
      endOfPeriodUnit: 'month',
    })
    const { configurationHint, hintLoading, setData } = useAi()
    const { formatExpPreviewText } = useCoupon()
    const handleConfirm = async (payload) => {
      payload.origin = payload.origin ? get(formData, 'value') : undefined
      const key = payload.origin ? 'couponCorrection.key' : 'coupon.key'
      const res = await configurationHint(get(hint, key), payload)
      if (!res) {
        hintLoading.value = false
        return
      }
      setData(formData.value, res)
    }
    return {
      handleConfirm,
      hintLoading,
      formData,
      formatExpPreviewText,
    }
  },
  data: () => ({
    mode: 'create',
    datePickerOptions: {
      disabledDate (d) {
        return d.getTime() < Date.now()
      },
    },
    expTypeOption: [
      {
        label: '領取後可使用天數',
        value: 'expDay',
      },
      {
        label: '特定截止日期',
        value: 'expAt',
      },
      {
        label: '領取當月最後一天',
        value: 'endOfPeriod',
      },
    ],
    // formData: {
    //   expAt: '',
    //   expDay: '',
    //   expType: 'expDay',
    //   img: null,
    //   name: '',
    //   exp: '',
    //   notice: '',
    // },

    formRules: {
      name: [noEmptyRules()],
      expType: [noEmptyRules()],
      expDay: [noEmptyRules(), isDigitRules(), maxNumberRules(9999)],
      expAt: [noEmptyRules()],

      // img: [noEmptyRules()],
    },

    editorOption: {
      modules: {
        toolbar: [
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
          ['link'],
        ],
      },
    },
  }),

  computed: {
    ...mapGetters(['shop']),

    pageTitle () {
      let title = ''
      if (this.mode === 'create') title = '新增使用券'
      else title = '編輯使用券'
      return title
    },

    btnTitle () {
      let title = ''
      if (this.mode === 'create') title = '儲存'
      else title = '編輯'
      return title
    },
  },

  async mounted () {
    if (!this.$route.params.id) return
    this.mode = 'update'
    await this.findCoupon()
  },

  methods: {
    async confirm () {
      if (this.mode === 'create') await this.createCoupon()
      else await this.updateCoupon()
    },
    async createCoupon () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      if (!this.formData.notice) {
        return this.$message.warning('請輸入注意事項！')
      }

      const expType = this.formData.expType

      const [, err] = await CreateCoupon({
        shopId: this.shop,
        image: this.formData.img ? this.formData.img.id : undefined,
        name: this.formData.name,
        expType,
        expAt:
          expType === 'expAt'
            ? dayjs(this.formData.expAt).format('YYYY/MM/DD HH:mm:ss')
            : undefined,
        expDay: expType === 'expDay' ? Number(this.formData.expDay) : undefined,
        endOfPeriodUnit: this.formData.endOfPeriodUnit,
        showMinute: 3,
        notice: this.formData.notice,
      })

      if (err) return this.$message.error(err)
      this.$message.success('新增使用券成功！')
      this.$router.go(-1)
    },

    async findCoupon () {
      const [res, err] = await FindCoupon({
        shopId: this.shop,
        id: this.$route.params.id,
      })
      if (err) return this.$message.error(err)

      this.formData.img = res.Image
      this.formData.name = res.name
      this.formData.expDay = res.expDay
      this.formData.expAt = res.expAt
      this.formData.expType = res.expType
      this.formData.notice = res.notice
    },

    onExpTypeChange (type) {
      this.formData.expDay = null
      this.formData.expAt = null
      this.formData.endOfPeriodUnit = null
      if (type === 'endOfPeriod') {
        this.formData.endOfPeriodUnit = 'month'
      }
    },

    async updateCoupon () {
      const expType = this.formData.expType

      const [, err] = await UpdateCoupon({
        shopId: this.shop,
        id: this.$route.params.id,
        image: this.formData.img ? this.formData.img.id : undefined,
        name: this.formData.name,
        // expType,
        // expAt: expType === 'expAt' ? dayjs(this.formData.expAt).format('YYYY/MM/DD') : undefined,
        expDay: expType === 'expDay' ? Number(this.formData.expDay) : null,
        showMinute: 3,
        notice: this.formData.notice,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新使用券成功！')
      await this.findCoupon()
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .ql-container.ql-snow {
  @apply min-h-[200px];
}

.page-footer {
  @apply text-right;
}

::v-deep .el-input {
  @apply w-full;
}
::v-deep .el-select {
  @apply w-full;
}
</style>
